import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import path from '@/config/path';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {
        title: '枢智Linkzo',
        needLogin: true
      },
      name: 'adminPage',
      component: () => import('@/pages/admin/admin.vue')
    },
    {
      path: '/index',
      meta: {
        title: '枢智Linkzo',
        needLogin: true
      },
      name: 'adminPage',
      component: () => import('@/pages/admin/admin.vue')
    },
    {
      path: '/szyk',
      name: 'szyk',
      key: 'szyk',
      component: () => import('@/pages/saasSevices/serviceStatic')
    },
    {
      path: '/szqz',
      name: 'szqz',
      key: 'szqz',
      component: () => import('@/pages/saasSevices/serviceStatic')
    },
    {
      path: '/szwk',
      name: 'szwk',
      key: 'szwk',
      component: () => import('@/pages/saasSevices/serviceStatic')
    },
    {
      path: '/szbe',
      name: 'szbe',
      key: 'szbe',
      component: () => import('@/pages/saasSevices/serviceStatic')
    },
    {
      path: '/hardware/chapterCylinder',
      name: 'chapterCylinder',
      key: 'chapterCylinder',
      component: () => import('@/pages/hardware/chapterCylinder')
    },
    {
      path: '/hardware/square',
      name: 'squarePage',
      key: 'square',
      component: () => import('@/pages/hardware/square')
    },
    {
      path: '/customer/sclqCase',
      name: 'sclqCase',
      component: () => import('@/pages/customer/sclqCase.vue')
    },
    {
      path: '/customer/zxgaCase',
      name: 'zxgaCase',
      component: () => import('@/pages/customer/zxgaCase.vue')
    },
    {
      path: '/customer/caseList',
      name: 'caseList',
      component: () => import('@/pages/customer/caseList.vue')
    },
    {
      path: '/about/aboutUs',
      name: 'aboutPage',
      component: () => import('@/pages/about/aboutUs.vue')
    },
    {
      path: '/about/brand',
      name: 'brandPage',
      component: () => import('@/pages/about/brand.vue')
    },
    {
      path: '/about/brandDetail',
      name: 'brandDetailPage',
      component: () => import('@/pages/about/brandDetail.vue')
    },
    {
      path: '/form/form',
      name: 'formPage',
      component: () => import('@/pages/form/form.vue')
    },
    {
      path: '/products',
      name: 'productPage',
      component: () => import('@/pages/product/product.vue')
    },
    {
      path: '*', redirect: '/'
    }
  ]
});

router.beforeEach((to, from, next) => {
  document.title = (to.meta && to.meta.title) || '枢智Linkzo';
  if (to.meta.needLogin) {
    let userInfo = Store.state.user;
    if (!userInfo) {
      next(`${path.loginPage}?redirect=${encodeURIComponent(to.fullPath)}`);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
