<template>
  <section class="footer-section">
    <div class="footer-mian">
      <div class="lists">
        <ul class="footer-hover">
          <li>
            <h5>产品中心</h5>
          </li>
          <li><a @click="handlePageJump('/szyk')">枢智印控</a></li>
          <li><a @click="handlePageJump('/szqz')">枢智签章</a></li>
          <li><a @click="handlePageJump('/szwk')">枢智文控</a></li>
          <li><a @click="handlePageJump('/szbe')">管理后台</a></li>
        </ul>
        <ul class="footer-hover">
          <li>
            <h5>智能硬件</h5>
          </li>
          <li><a @click="handlePageJump('/hardware/chapterCylinder')">枢智章筒</a></li>
          <!-- <li>枢智章台</li> -->
          <li><a @click="handlePageJump('/hardware/square')">枢智方舱</a></li>
        </ul>
        <ul class="footer-hover">
          <li>
            <h5>关于我们</h5>
          </li>
          <li><a @click="handlePageJump('/about/aboutUs')">关于枢智</a></li>
          <li><a @click="handlePageJump('/about/brand')">品牌动态</a></li>
        </ul>
        <ul>
          <li>
            <h5>关注或联系我们</h5>
          </li>
          <li>关注枢智官方微信公众号</li>
          <li>
            <div class="qr-code">
              <img width="76" height="76" title="wechat.icon" :src="`${this.IMG_PREFIX}qrcode.jpg`"/>
            </div>
          </li>
          <li class="hot-line">
            <div>
              电话热线：
              <h5>400-112-9622</h5>
            </div>
            <div>工作日09:00-21:00（周末至20:00）</div>
          </li>
        </ul>
      </div>
      <el-divider class="divid-line"></el-divider>
      <div class="copyright">
        版权所有【中基数智（成都）科技有限公司】
        <a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2020034115号-1</a>
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005858"
           class="public_url">
          <img width="20" height="20" :src="`${this.IMG_PREFIX}public_icon.png`" class="public_icon"/>
          <p class="public_info">川公网安备
            51019002005858号
          </p>
        </a>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "pageFooter",
  methods: {
    handlePageJump(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style lang="less" scoped>
.footer-section {
  background: #f2f2f2;
  padding: 40px 0;
  min-width: 1178px;

  .footer-mian {
    width: 1178px;
    margin: 0 auto;

    .lists {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      font-size: 12px;
      color: #4e5969;

      .footer-hover {
        li {
          & > a {
            display: inline-block;
            cursor: pointer;

            &:hover {
              color: #3c67f6;
            }
          }
        }
      }

      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &.hot-line {
          display: flex;
          flex-direction: column;

          & > div:first-child {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            h5 {
              font-size: 16px;
              cursor: pointer;

              &:hover {
                color: #3c67f6;
              }
            }
          }

          & > div:last-child {
            color: #86909c;
          }
        }

        h5 {
          color: #1d2129;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .qr-code {
        width: 82px;
        height: 82px;
        background: #ffffff;
        border: 2px solid #f2f3f5;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 72px;
          height: 72px;
          border: 2px solid #f2f3f5;
          border-radius: 2px;
        }
      }
    }

    .divid-line {
      margin: 40px 0;
    }

    .copyright {
      display: flex;
      font-size: 12px;
      color: #4e5969;
      justify-content: center;
      height: 20px;
      line-height: 24px;

      .public_icon {
        margin: 0 5px 0 20px;
        float:left;
      }

      .public_info {
        float: left;
        height: 20px;
        line-height: 24px;
      }

      public_url {
        display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height: 24px;
      }

      a {
        color: #4e5969;

        &:hover {
          color: #3c67f6;
          text-decoration: none;
        }
      }
    }

  }
}
</style>