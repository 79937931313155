import {apiCheckLogin, apiGetLoginInfo} from "@/services/login";

const user = {
    namespaced: true,
    state: {
        userInfo: null
    },
    mutations: {
        setUserInfo(state, obj) {
            state.userInfo = obj;
        },
    },
    actions: {
        async checkLoginAndGetLoginInfo({commit}) {
            const {err} = await apiCheckLogin();
            if (!err) {
                const {err, data} = await apiGetLoginInfo();
                if (!err) {
                    commit('setUserInfo', data);
                    return true;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
    }
}
export default user;