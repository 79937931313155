export function getCookie(name) {
    var arr,
        reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return null;
}

export function setCookie(token) {
    document.cookie = `Authtoken=${token};domain=linkzo.cn;path=/;expires=${new Date(
        new Date().getTime() + 360 * 24 * 60 * 60 * 1000,
    )}`;
}

export function clearCookie() {
    document.cookie = `Authtoken=;domain=linkzo.cn;path=/`;
}