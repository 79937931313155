<template>
  <div class="header">
    <div class="header-content content-with-width flex-row-between-center">
      <el-menu
        :default-active="$route.path"
        mode="horizontal"
        text-color="#1D2129"
        active-text-color="#3C67F6"
        class="header-menu"
        :router="true"
      >
        <el-menu-item index="1" style="padding-left: 0px">
          <a href="/">
            <img alt="枢智" width="90" class="linkzo-icon" :src="`${IMG_PREFIX}linkzo-icon.png`" />
          </a>
        </el-menu-item>
        <el-submenu index="2" popper-class="pop-menu" :popper-append-to-body="false">
          <template slot="title">
            <span class="title">产品中心</span>
          </template>
          <el-menu-item index="/szyk" :route="{path: '/szyk'}">枢智印控</el-menu-item>
          <el-menu-item index="/szqz" :route="{path: '/szqz'}">枢智签章</el-menu-item>
          <el-menu-item index="/szwk" :route="{path: '/szwk'}">枢智文控</el-menu-item>
          <el-menu-item index="/szbe" :route="{path: '/szbe'}">管理后台</el-menu-item>
        </el-submenu>
        <el-submenu index="3" popper-class="pop-menu" :popper-append-to-body="false">
          <template slot="title">
            <span class="title">智能硬件</span>
          </template>
          <el-menu-item index="/hardware/chapterCylinder" :route="{path: '/hardware/chapterCylinder'}">
            <a>枢智章筒</a>
          </el-menu-item>
          <!-- <el-menu-item index="3-2">
            <a>枢智章台</a>
          </el-menu-item> -->
          <el-menu-item index="/hardware/square" :route="{path: '/hardware/square'}">
            <a>枢智方舱</a>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/customer/caseList" :route="{path: '/customer/caseList'}">
          <span class="title">客户案例</span>
        </el-menu-item>
        <el-submenu index="5" popper-class="pop-menu" :popper-append-to-body="false">
          <template slot="title">
            <span class="title">关于我们</span>
          </template>
          <el-menu-item index="/about/aboutUs" :route="{path: '/about/aboutUs'}">关于枢智</el-menu-item>
          <el-menu-item index="/about/brand" :route="{path: '/about/brand'}">
            <a class="title">品牌动态</a>
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <div class="right flex-row-start-center">
        <div class="phone-number">
          <div class="phone-icon"></div>
          <span>400-112-9622</span>
        </div>
        <button class="web-button-primary" @click="$router.push('/form/form')">免费试用</button>
        <button class="web-button-default">
          <a class="router-txt" href="https://workshop.linkzo.cn/login">登录</a>
        </button>
      </div>
    </div>

    <div class="go-back" v-if="isShowBack" @click="handlerBack">
      <div class="back-btn content-with-width">
        <i class="el-icon-arrow-left icon-back"></i>
        <div>返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminPage",
  data: () => ({
    activeIndex: "/",
    isShowBack: false,
  }),
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.activeIndex = to.path
      }

      //显示返回tab
      to.path == '/about/brandDetail' ? this.isShowBack = true : this.isShowBack = false
    }
  },
  methods: {
    handlePageJump(path) {
      this.$router.push(path);
    },

    handlerBack() {
      this.$router.go(-1);
    }
    // handleSelect(path, pathKey) {
    //   console.log(path, pathKey, '----pppp');
    // }
  }
};
</script>

<style lang="less" scoped>
@prefix: "https://img.linkzo.cn/officialWebsite/images";
.header {
  width: 100%;
  height: 64px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(23, 28, 43, 0.03);
  z-index: 200;

  .header-content {
    height: 100%;
    min-width: 1178px;
    background-color: #fff;
    /deep/ .el-menu {
      border: 0;
      .el-menu-item {
        display: flex;
        align-items: center;
        &:first-child {
          padding-left: 0;
        }
      }
      .el-submenu {
        .pop-menu {
          margin-top: -10px;
          .el-menu.el-menu--popup {
            padding-top: 10px;
            padding-bottom: 12px;
            min-width: 108px !important;
            margin-left: -23px;
          }
          .el-menu-item {
            height: auto;
            margin: 0;
            min-width: 108px;
            padding: 0;
            text-align: center;
            font-size: 15px;
            height: 39;
            line-height: 39px;
            padding: 0 24px;
            // a {
            //   width: 100%;
            //   text-align: center;
            //   font-size: 15px;
            //   height: 39;
            //   line-height: 39px;
            //   padding: 0 24px;
            // }
          }
        }
      }
    }
  }

  .left {
    > div {
      margin-right: 32px;
      cursor: pointer;
    }

    .linkzo {
      height: 32px;
      width: 90px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  .router-txt {
    font-size: 16px;
    line-height: 22px;
    color: #1d2129;

    &:hover {
      color: #3c67f6;
    }
  }
  .right {
    .connect-phone-icon {
      height: 24px;
      width: 24px;
    }

    .phone-number {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      color: #1d2129;
      margin-right: 16px;

      .phone-icon {
        display: inline-block;
        width: 23px;
        height: 23px;
        background-image: url("@{prefix}/connect-phone.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }
      &:hover {
        cursor: pointer;
        color: #4e5969;
        .phone-icon {
          background-image: url("@{prefix}/connect-phone-hover.svg");
        }
      }
    }
    & > button {
      margin-right: 16px;
      padding: 10px 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .go-back {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color: #4e5969;
    height: 48px;
    line-height: 48px;
    background: #F7F7F7;
    width: 100%;
    min-width: 1178px;
    .back-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        color: #1B59F5;
        .icon-back {
          color: #1B59F5;
        }
      }
      .icon-back {
        color: #4e5969;
        font-size: 16px;
      }
      .back-btn {
        cursor: pointer;
      }
    }
  }
}

.title {
  font-size: 16px;
}
</style>
